module.exports = [{
  plugin: require('/Users/vladstirbu/Documents/code/smallthings/site/node_modules/gatsby-remark-images/gatsby-browser.js'),
  options: {
    "plugins": [],
    "maxWidth": 590
  }
}, {
  plugin: require('/Users/vladstirbu/Documents/code/smallthings/site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/Users/vladstirbu/Documents/code/smallthings/site/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/Users/vladstirbu/Documents/code/smallthings/site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingId": "UA-143196683-1",
    "anonymize": true,
    "respectDNT": true
  }
}, {
  plugin: require('/Users/vladstirbu/Documents/code/smallthings/site/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];